import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useUser } from '@util/providers/AuthProvider';
import { usePlan } from '@util/providers/Plan';

export default function SubscribeButton({ plan }) {
  const { t } = useTranslation();
  const { ssoUser, bmoUser } = useUser();
  const myPlan = getMyPlan();
  const {
    termIsYearly,
    setSelectedPlan,
    setSelectedRate,
    trialLength,
  } = usePlan();

  function getMyPlan() {
    const isCommunityUser = bmoUser && !bmoUser.subscriptionInfo;
    const isPersonalUser =
      bmoUser &&
      bmoUser.subscriptionInfo &&
      bmoUser.subscriptionInfo.planType.toLowerCase() === 'family';
    const isProfessionalUser =
      bmoUser &&
      bmoUser.subscriptionInfo &&
      bmoUser.subscriptionInfo.planType.toLowerCase() === 'classroom';

    const isDistrictUser =
      bmoUser &&
      bmoUser.subscriptionInfo &&
      bmoUser.subscriptionInfo.planType.toLowerCase() === 'district';


    if (isCommunityUser) {
      return 'community';
    } else if (isPersonalUser) {
      return 'personal';
    } else if (isProfessionalUser) {
      return 'professional';
    } else if (isDistrictUser) {
      return 'district';
    } 
    return null;
  }

  const canTrial =
    bmoUser && bmoUser.subscriptionInfo
      ? bmoUser.subscriptionInfo.isTrialEligible
      : true;

  function getRatePlanByType(p) {
    return (
      p.staticRatePlan ||
      p.ratePlans.find(rp => (termIsYearly ? rp.isYearly : rp.isMonthly))
    );
  }
  return (
    <>
      <div>
        {plan.key === 'personal' || plan.key === 'professional' ? (
          <UncontrolledDropdown>
            <DropdownToggle
              id={'GetStarted' + plan?.key}
              data-testid={'GetStarted' + plan?.key}
              color="primary"
              caret
              disabled={myPlan === "district"}
            >
              {t('action.getStarted')}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                key="subscribe"
                id={'SubscribeLink' + plan?.key}
                data-testid={'SubscribeLink' + plan?.key}
                onClick={() => {
                  console.log('TrialLinkClick');
                  setSelectedPlan(plan);
                  setSelectedRate(getRatePlanByType(plan));
                  navigate(
                    ssoUser
                      ? plan.planSelectRoutes.loggedIn
                      : plan.planSelectRoutes.loggedOut
                  );
                }}
              >
                {t(plan.actionText)}
              </DropdownItem>
              {plan.hasTrial && canTrial && (
                <DropdownItem
                  id={'TrialLink' + plan?.key}
                  data-testid={'TrialLink' + plan?.key}
                  onClick={() => {
                    setSelectedPlan(plan);
                    setSelectedRate(
                      plan.ratePlans.filter(
                        ratePlan => ratePlan.isTrial === true
                      )[0]
                    );
                    navigate(
                      ssoUser
                        ? plan.planSelectRoutes.trial.loggedIn
                        : plan.planSelectRoutes.trial.loggedOut
                    );
                  }}
                  key="trial"
                >
                  {t('action.startTrial')}
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : plan.key === 'community' && myPlan === 'community' ? (
          <button
            id={'MyPlanLink' + plan?.key}
            data-testid={'MyPlanLink' + plan?.key}
            className="btn btn-outline-opaque-secondary"
            onClick={() => {
              navigate('/redirect', {
                state: {
                  extension: '/You/Home',
                },
              });
            }}
          >
            {t('planCard.myPlan')}
          </button>
        ) : (
          <button
            id={'SubscribeLink' + plan?.key}
            data-testid={'SubscribeLink' + plan?.key}
            className="btn btn-primary"
            disabled={myPlan === "district"}
            onClick={() => {
              setSelectedPlan(plan);
              setSelectedRate(getRatePlanByType(plan));
              navigate(
                ssoUser
                  ? plan.planSelectRoutes.loggedIn
                  : plan.planSelectRoutes.loggedOut
              );
            }}
          >
            {t(plan.actionText)}
          </button>
        )}
      </div>
    </>
  );
}

const LightBold = styled.span`
  font-weight: 600;
`;
